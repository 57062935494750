import BackButton from '@/components/BackButton';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import {
  Body,
  BookingContainer,
  BookingForm,
  ContentButton,
  Footer,
  Header,
  PaymentButton,
} from './style';

import { formatPriceWithNoUnit } from '@/utils/formatPrice';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useTourBookingContext } from '../providers/useTourBookingContext';

import ConfirmModal from '@/components/ConfirmModal';
import useTourBooking from '@/hooks/useTourBooking';
import { paymentService } from '@/services/paymentService';
import { tourBookingService } from '@/services/tourBookingService';
import useStoreApp from '@/store';
import { PaymentType } from '@/types/consistency';
import { ICreateTourBooking } from '@/types/interface';
import { useRouter } from 'next/router';
import { useRouter as useRouterNext13 } from 'next13-progressbar';
import ErrorSubmitBar from '@/components/ErrorSubmitBar';

type Props = {
  onClose: () => void;
};

enum PaymentMethodEnum {
  PAYPAL = 'PAYPAL',
  VNPAY = 'VNPAY',
  PAY_LATER = 'PAY_LATER',
}

type PaymentMethod = keyof typeof PaymentMethodEnum;
const formatTime = 'HH:mm';

export default function TourBookingPayment({ onClose }: Props) {
  const { t } = useTranslation(['validate', 'common', 'error']);
  const router = useRouter();
  const routerNext13 = useRouterNext13();

  const {
    tourPrice,
    servicesPrice,
    totalAmount,
    tourBookingDoneId,
    bookingTourData,
    tourDetail,
    contactInfo,
  } = useTourBookingContext();
  const { doSubmit, successBooking, bookingDone, loading, bookingErrMsg } =
    useTourBooking({
      service: tourBookingService,
      tourType: undefined,
      currentTour: null
    });
  const { languageId } = useStoreApp(state => state.globalSlice);

  const [collaboratorId] = useState<string | undefined>(
    router.query.collaboratorId as string | undefined,
  );
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(
    null,
  );
  const [isCancelled, setIsCancelled] = useState<boolean>(false);

  const handlePaymentMethodChange = (method: PaymentMethod) => {
    setPaymentMethod(method);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const onVNPayPayment = useCallback(async () => {
    if (bookingDone.id !== null) {
      const res = await paymentService.getVNPayUrl({
        id: bookingDone.id,
        paymentType: PaymentType.VNPAY.code,
      });

      if (res.data.vnpUrlReturn) window.open(res.data.vnpUrlReturn, '_self');
    }
  }, [bookingDone]);

  const onSubmit = useCallback(() => {
    const parseTime = bookingTourData.time.format(formatTime);
    const parseDate = bookingTourData.date.format('YYYY-MM-DD');
    let scheduleDate: Date = new Date(`${parseDate}T${parseTime}:00`);

    let paymentType = PaymentType.Cash.code

    if (paymentMethod === PaymentMethodEnum.VNPAY) {
      paymentType = PaymentType.VNPAY.code;
    }

    let body: ICreateTourBooking = {
      tourId: tourDetail!.id,
      duration: bookingTourData.duration,
      scheduleDate,
      additionalServiceIds: bookingTourData.additionalServiceIds,
      pickUpAddress: bookingTourData.pickUpAddress,
      pickUp: bookingTourData.pickUp,
      passengerNormal: bookingTourData.passengerNormal,
      passengerHalf: bookingTourData.passengerHalf ?? 0,
      note: bookingTourData.note,
      contactInfo,
      languageId,
      collaboratorId,
      paymentType: paymentType,
    };

    doSubmit(body);

  }, [
    bookingTourData.additionalServiceIds,
    bookingTourData.date,
    bookingTourData.duration,
    bookingTourData.note,
    bookingTourData.passengerHalf,
    bookingTourData.passengerNormal,
    bookingTourData.pickUp,
    bookingTourData.pickUpAddress,
    bookingTourData.time,
    collaboratorId,
    contactInfo,
    doSubmit,
    languageId,
    tourDetail,
    paymentMethod,
  ]);

  useEffect(() => {
    if (!successBooking) return;

    switch (paymentMethod) {
      case PaymentMethodEnum.PAYPAL:
        // Logic for Paypal payment
        break;
      case PaymentMethodEnum.VNPAY:
        onVNPayPayment();
        break;
      case PaymentMethodEnum.PAY_LATER:
        if (bookingDone.codeTracking) {
          const bookingUrl = 'booking/' + bookingDone.codeTracking;
          console.log('push bookingUrl', bookingUrl);
          routerNext13.push(bookingUrl);
        }
        break;
      default:
        // Default logic
        break;
    }
  }, [
    bookingDone,
    onVNPayPayment,
    paymentMethod,
    routerNext13,
    successBooking,
  ]);

  return (
    <>
      <BookingContainer py={4}>
        <Grid container justifyContent="center">
          <Grid
            item
            sm={12}
            xs={12}
            display={isMobile ? 'block' : 'none'}
            sx={{
              pt: '12px',
            }}
          >
            <BackButton onClick={() => setIsCancelled(true)} />
          </Grid>

          <Grid
            item
            md={6}
            lg={6}
            xs={10}
            sx={{
              pt: {
                xs: '12px',
                sm: '12px',
                md: '84px',
              },
            }}
          >
            <BookingForm>
              <Header>
                <Typography variant="h5" fontWeight={600}>
                  {t('common:paymentInfo')}
                </Typography>
                <Button
                  sx={{
                    p: 0,
                    height: 'auto',
                    display: isMobile ? 'none' : 'block',
                  }}
                  onClick={() => setIsCancelled(true)}
                >
                  <Image
                    src="/assets/icons/close.svg"
                    alt="close"
                    height={13.5}
                    width={13.5}
                  />
                </Button>
              </Header>

              <Body gap={3}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      sx={{
                        fontSize: {
                          xs: '14px',
                          sm: '16px',
                        },
                      }}
                    >
                      {t('common:tourPrice')}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      sx={{
                        fontSize: {
                          xs: '14px',
                          sm: '16px',
                        },
                      }}
                    >
                      {formatPriceWithNoUnit(tourPrice)} VNĐ
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      sx={{
                        fontSize: {
                          xs: '14px',
                          sm: '16px',
                        },
                      }}
                    >
                      {t('common:additionalService')}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      sx={{
                        fontSize: {
                          xs: '14px',
                          sm: '16px',
                        },
                      }}
                    >
                      {formatPriceWithNoUnit(servicesPrice)} VNĐ
                    </Typography>
                  </Box>

                  {/* <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="body1" fontWeight={500}>
                      VAT 10%
                    </Typography>
                    <Typography variant="body1" fontWeight={500}>
                      {formatPriceWithNoUnit(vatPrice)} VNĐ
                    </Typography>
                  </Box> */}
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  color={t => t.palette.hightlight.main}
                  mb={1}
                >
                  <Typography
                    variant="h2"
                    fontWeight={700}
                    sx={{
                      fontSize: {
                        xs: '16px',
                        sm: '20px',
                      },
                    }}
                  >
                    {t('common:totalPayment')}
                  </Typography>
                  <Typography
                    variant="h2"
                    fontWeight={700}
                    sx={{
                      fontSize: {
                        xs: '16px',
                        sm: '20px',
                      },
                    }}
                  >
                    {formatPriceWithNoUnit(totalAmount)} VNĐ
                  </Typography>
                </Box>
              </Body>

              <Divider />

              <Body gap={1}>
                <Box py={1}>
                  <Typography variant="h5" fontWeight={600}>
                    {t('common:selectPayment')}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={1}
                >
                  <PaymentButton
                    variant={
                      paymentMethod === PaymentMethodEnum.PAYPAL
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() =>
                      handlePaymentMethodChange(PaymentMethodEnum.PAYPAL)
                    }
                  >
                    <ContentButton>
                      <Image
                        src="/assets/icons/paypal.svg"
                        alt="paypal"
                        height={48}
                        width={48}
                      />
                      <Typography variant="h4" fontWeight={600}>
                        Paypal
                      </Typography>
                    </ContentButton>
                  </PaymentButton>

                  <PaymentButton
                    variant={
                      paymentMethod === PaymentMethodEnum.VNPAY
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() =>
                      handlePaymentMethodChange(PaymentMethodEnum.VNPAY)
                    }
                  >
                    <ContentButton>
                      <Image
                        src="/assets/icons/vnpay.svg"
                        alt="vnpay"
                        height={48}
                        width={48}
                      />
                      <Typography variant="h4" fontWeight={600}>
                        VN Pay
                      </Typography>
                    </ContentButton>
                  </PaymentButton>

                  <PaymentButton
                    variant={
                      paymentMethod === PaymentMethodEnum.PAY_LATER
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() =>
                      handlePaymentMethodChange(PaymentMethodEnum.PAY_LATER)
                    }
                  >
                    <ContentButton>
                      <Typography variant="h4" fontWeight={600}>
                        {t('common:payLater')}
                      </Typography>
                    </ContentButton>
                  </PaymentButton>
                </Box>
              </Body>

              <Footer
                container
                columnSpacing={{ xs: 0, md: 3.5 }}
                rowSpacing={{ xs: 2, md: 0 }}
              >
                {/* Error for web */}
                {!isMobile && bookingErrMsg && (
                  <Grid item xs={12} sx={{ textAlign: 'end', marginBottom: 1 }}>
                    <Box letterSpacing={0.5}>
                      <Typography variant="body4" color="error.main">
                        {bookingErrMsg}
                      </Typography>
                    </Box>
                  </Grid>
                )}

                <Grid className='total-amount' item xs={12} md={6}>
                  {/* <ErrorSubmitBar
                    phone={tel}
                    error={Boolean(bookingErrMsg) || Boolean(errorPrice)}
                    errorText={bookingErrMsg || errorPrice}
                    buttonText={t('common:booking')}
                  /> */}

                  <Box
                    display="flex"
                    gap={1}
                    sx={{
                      flexDirection: {
                        xs: 'row',
                        md: 'column',
                      },
                      justifyContent: {
                        xs: 'space-between',
                        md: 'center',
                      },
                      alignItems: {
                        xs: 'center',
                        md: 'flex-end',
                      },
                    }}
                  >
                    <Typography variant="body4">
                      {t('common:totalAmount')}
                    </Typography>
                    <Typography
                      variant="body3"
                      fontWeight={600}
                      color={theme => theme.palette.hightlight.main}
                    >
                      {formatPriceWithNoUnit(totalAmount)} VNĐ
                    </Typography>
                  </Box>
                </Grid>

                <Grid className='paynow' item
                  xs={12} md={6}
                >
                  {/* Error for mobile */}
                  {
                    isMobile && bookingErrMsg && <Box letterSpacing={0.5} sx={{ marginBottom: 1, textAlign: 'center' }}>
                      <Typography variant="body4" color="error.main" >
                        {bookingErrMsg}
                      </Typography>
                    </Box>
                  }

                  {/*<Grid item xs={12} md={6} spacing={1}>*/}
                  {/* <ErrorSubmitBar
                    phone={tel}
                    error={Boolean(bookingErrMsg) || Boolean(errorPrice)}
                    errorText={bookingErrMsg || errorPrice}
                    buttonText={t('common:booking')}
                  /> */}

                  {/*</Grid>*/}
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={onSubmit}
                    disabled={paymentMethod === null || loading || bookingDone}
                  >
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Typography
                        variant="body3"
                        fontWeight={700}
                        color={theme => theme.palette.white.main}
                      >
                        {t('common:payNow')}
                      </Typography>
                    )}
                  </Button>
                </Grid>


              </Footer>
            </BookingForm>
          </Grid>
        </Grid>
        <ConfirmModal
          isOpen={isCancelled}
          title={t('common:confirmMove')}
          description={t('common:deleteBookingInfo')}
          yesAction={onClose}
          noAction={() => setIsCancelled(false)}
        />
      </BookingContainer >

      {/* {loading && <PreLoading backgroundColor="default" />} */}
    </>
  );
}
